.iconButton {
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    pointer-events: none;
    &:hover {
      cursor: not-allowed;
    }
  }
}
