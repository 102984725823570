@import "assets/stylesheets/scss/tokens";
.asideModalHeaderComponent {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
  width: 100%;
  .headerActions {
    display: flex;
    gap: 0.75rem
  }
}
.taskViewButton {
  background: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  .taskCountContainer {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    text-align: center;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    background: color-mix(in srgb, var(--theme-notice-soft-alert), transparent 25%);
    border: 1px solid;
    border-color: color-mix(in srgb, var(--theme-notice-heavy-alert), transparent 75%);
    min-width: 1.5rem;
    .taskCount {
      color: $theme-notice-heavy-alert;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1rem;
      margin: 0 2px;
    }
  }
}
