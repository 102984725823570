@import "./src/assets/stylesheets/scss/tokens";

.card {
  border-top: 1px solid $theme-border-primary;
  border-bottom: 1px solid $theme-border-primary;
  // This is to account for the box shadow on priority tasks
  padding-left: 5px;
  &.highPriority {
    box-shadow: 5px 0 0 0 $theme-notice-bold-alert inset;
  }
  .contentRow {
    word-break: break-word;
  }
  .noteLink {
    @include type-sm;
    vertical-align: middle;
    word-break: keep-all;
  }
  .highPriorityText {
    @include type-sm-bold;
  }
  .detailRow {
    padding: 0 0 0.25rem 0.75rem !important;
    display: flex;
    align-items: center;
    gap: 3%;
    .clientName {
      @include type-sm-bold;
      color: $theme-accent-primary;
      word-break: break-all;
      &.disabledState {
        pointer-events: none;
        color: $theme-text-secondary;
      }
    }
  }
  .tasksAvatarContainer {
    display: flex;
    .taskClient {
      margin-left: -0.5rem;
    }
  }
  .avatarAndDropdown {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }
  .disabledState {
    pointer-events: none;
    color: $theme-text-secondary;
  }
}


